import React, { FC, useState, useEffect } from "react";
import styled, { css } from "@xstyled/styled-components";

import { LayoutProps, SpaceProps, FlexboxProps } from "styled-system";
import { StyleSheetManager } from "styled-components";

import { makeCorsImageRequest, generateAssetUrl } from "../../helpers";
// import placeholderImage from '../../assets/placeholderProduct.png';
import { SyntheticEvent } from "react";

interface IImageProps extends LayoutProps, SpaceProps, FlexboxProps {
  round?: boolean;
  src: string;
  base64?: boolean;
  alt: string;
  role?: string;
  onClick?: () => void;
  border?: string;
}

export const StyledImage = styled.imgBox``;

export const Image: FC<IImageProps> = ({
  src,
  round,
  alt,
  role,
  base64,
  ...props
}) => {
  const [imageSource, setImageSource] = useState<string>("");
  useEffect(() => {
    if (base64) {
      makeCorsImageRequest(
        src,
        "GET",
        (body: string | ArrayBuffer | null, err?: string) => {
          if (body) {
            setImageSource(body as string);
          } else if (err) {
            throw Error(err);
          }
        }
      );
    } else {
      setImageSource(src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const fallbackOnError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    // if (e.currentTarget && e.currentTarget.src) e.currentTarget.src = placeholderImage;
  };

  return (
    <StyleSheetManager disableCSSOMInjection={true} disableVendorPrefixes={true}>
      <StyledImage
        {...props}
        src={imageSource}
        alt={alt}
        role={role}
        onError={fallbackOnError}
        style={{ padding: 0 }}
      />
    </StyleSheetManager>
  );
};

interface IAvatarProps extends IImageProps {
  size: number;
}

export const Avatar = styled(Image)`
  width: ${(props: IAvatarProps) => props.size};
  height: ${(props: IAvatarProps) => props.size};
`;

export const placeholderProductImage = generateAssetUrl(
  "2309125",
  "caas-standard"
);
export const placeholderProductImageThumb = generateAssetUrl(
  "2309125",
  "caas-thumb"
);
