import React, { FC, ReactElement } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import {Image} from '../shared/media'
import {generateAssetUrl } from '../helpers';
import { gridRow } from "styled-system";

export interface IHeaderProps {}

export const Contact : FC<IHeaderProps> = () : ReactElement => {

    return (
           <Container className='contact-container'>
               <a className="hide508" href="#contact">Skip to Main Content</a>
               <Row xs={1} md={2} className='contact-body'>
                    <Col xs={{span: 12}} lg={{span: 4, order: 'first'}} xl={{span: 4, order: 'first'}} md={{span: 12}} className='contact-info-container'>
                        <Container className='contact-info-inner' role="main">
                        <Row id="contact">
                            <h1 className='contact-heading'>Contact Us</h1>
                            {/* <div className='contact-heading'>Contact Us</div> */}
                        </Row>
                        <Row >
                            {/* <div className="contact-sub-heading">Corporate</div> */}
                            <h5 className="contact-sub-heading">Corporate</h5>
                            <div className="corporate-details">Email: SGaillard@hmrichards.com</div>
                            <div className="corporate-details">Address: PO Box 373 Baldwyn, MS 38824</div>
                            <div className="corporate-details">Tel: 662.365.9485</div>
                        </Row>
                        <hr className='contact-info-divider' />
                        <Row className='high-point'>
                            {/* <div className="contact-sub-heading">High Point Showroom Address</div> */}
                            <h5 className="contact-sub-heading">High Point Showroom Address</h5>
                            <div className="corporate-details">Market On Green</div>
                            <div className="corporate-details">Address: 212 E. Green Drive 2nd Floor</div>
                            <div className="corporate-details">High Point, NC 27260</div>
                        </Row>
                        </Container>
                    </Col>
                    <Col lg={{span: 8}} xl={8} xs={{span : 12, order  : 'first'}} md={{span: 12}} className='contact-image'>
                        <Image
                        maxWidth='100%'
                        // height='100%'
                        // minHeight='35vw'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('34633399','caas-standard')}
                        alt={``}
                        role='presentation'
                        aria-hidden='true'
                        />
                    </Col>
                    <Col lg={8} xs={{span : 12, order  : 'first'}} md={{span: 12}} className='contact-image-mob'>
                        <Image
                        maxWidth='100%'
                        height='auto'
                        //minHeight='35vw'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('34829731','caas-standard')}
                        alt={``}
                        role='presentation'
                        aria-hidden='true'
                        />
                    </Col>
                </Row>
           </Container>
    )
}