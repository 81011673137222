import * as ReactDOM from "react-dom";
import * as React from "react";
import { Home } from "./layouts/Home/Home";
import { About } from "./layouts/About";
import { Quality } from "./layouts/Quality";
import { Contact } from "./layouts/Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./shared/Header/Header";
import { Footer } from "./shared/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/styles.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div className="app-container">
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/quality" element={<Quality />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
