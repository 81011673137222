import React, { FC, ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface IHeaderProps {}

export const Footer: FC<IHeaderProps> = (): ReactElement => {
  return (
    <Container className="footer-container">
      <Row className="footer-heading">
        <Col className="footer-label" sm={4} md={4}>
          <h1 className="footer-header">About HM Richards</h1>
        </Col>
        <Col className="footer-links-container">
          <ul className="footer-items">
            <li className="footer-navigation-item">
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: "small",
                  color: "#fff",
                }}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="footer-navigation-item">
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: "small",
                  color: "#fff",
                }}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="footer-navigation-item">
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: "small",
                  color: "#fff",
                }}
                to="/quality"
              >
                Quality
              </Link>
            </li>
            <li className="footer-navigation-item">
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: "small",
                  color: "#fff",
                }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="footer-navigation-item">
              <a
                style={{
                  textDecoration: "none",
                  fontSize: "small",
                  color: "#fff",
                }}
                className="normal-header-link"
                href="https://apply.jobappnetwork.com/hm-richards/en"
                target={"_hmriCareers"}
              >
                Careers
              </a>
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="footer-description">
        <Col className="footer-text">
          Our mission at H.M. Richards, Inc. is to make beautiful furniture at
          our US facility that delivers on style, quality and value.
        </Col>
        <Col className="empty-column"></Col>
      </Row>
      {/* <Row className="footer-description">
        <Col className="footer-text">
          UnitedHealthcare creates and publishes the Machine-Readable Files on
          behalf of H.M. Richards. To link to the Machine-Readable Files, please
          click on the URL provided:{" "}
          <a
            href="https://transparency-in-coverage.uhc.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://transparency-in-coverage.uhc.com/
          </a>
        </Col>
        <Col className="empty-column"></Col>
      </Row> */}
      <Row className="footer-copyright">
        <Col className="footer-text">
          &copy; HM Richards, Inc. | All rights reserved
        </Col>
      </Row>
    </Container>
  );
};
