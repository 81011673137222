import React, { FC, ReactElement } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Image } from "../media";
import { generateAssetUrl } from "../../helpers";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

export interface IHeaderProps {}

export const Header: FC<IHeaderProps> = (): ReactElement => {
  const [showSideNav, setShowSideNav] = useState(false);

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        style={{ backgroundColor: "#000000" }}
        className="normal-navbar"
      >
        <Container className="header-container">
          <Link to="/" style={{ width: "25%" }}>
            <Navbar.Brand>
              <img
                alt="HM Richards logo"
                src={require("./hmri-logo.png")}
                className="header-logo"
              />
            </Navbar.Brand>
          </Link>
          <Nav className="mr-auto">
            <ul className="normal-header-items">
              <li>
                <Link className="normal-header-link" to="/">
                  HOME
                </Link>
              </li>
              <li>
                <Link className="normal-header-link" to="/about">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link className="normal-header-link" to="/quality">
                  QUALITY
                </Link>
              </li>
              <li>
                <Link className="normal-header-link" to="/contact">
                  CONTACT
                </Link>
              </li>
              <li>
                <a
                  className="normal-header-link"
                  href="https://apply.jobappnetwork.com/hm-richards/en"
                  target={"_hmriCareers"}
                >
                  CAREERS
                </a>
              </li>
            </ul>
          </Nav>
        </Container>
      </Navbar>
      <Navbar bg="dark" expand={false} className="offcanvas-navbar">
        <Container fluid>
          <Link to="/" style={{ width: "70%" }}>
            <Navbar.Brand>
              <img
                alt="HM Richards logo"
                src={require("./hmri-logo.png")}
                className="header-logo"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={() => setShowSideNav(true)}
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            placement="end"
            show={showSideNav}
            onHide={() => setShowSideNav(false)}
          >
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <ul className="offcanvas-list">
                  <li className="header-navigation-item">
                    <Link
                      onClick={() => setShowSideNav(false)}
                      className="normal-header-link"
                      to="/"
                    >
                      HOME
                    </Link>
                  </li>
                  <li className="header-navigation-item">
                    <Link
                      onClick={() => setShowSideNav(false)}
                      className="normal-header-link"
                      to="/about"
                    >
                      ABOUT
                    </Link>
                  </li>
                  <li className="header-navigation-item">
                    <Link
                      onClick={() => setShowSideNav(false)}
                      className="normal-header-link"
                      to="/quality"
                    >
                      QUALITY
                    </Link>
                  </li>
                  <li className="header-navigation-item">
                    <Link
                      onClick={() => setShowSideNav(false)}
                      className="normal-header-link"
                      to="/contact"
                    >
                      CONTACT
                    </Link>
                  </li>
                  <li className="header-navigation-item">
                    <a
                      className="normal-header-link"
                      href="https://apply.jobappnetwork.com/hm-richards/en"
                      target={"_hmriCareers"}
                    >
                      CAREERS
                    </a>
                  </li>
                </ul>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};
