let config = require('../buildSettings');

type ImageType = 'caas-standard' | 'caas-thumb' | 'caas-squarethumb' | 'eComm-thumb' | '';

// Helper for creating URLs for CDN storage
// blobName: resource name and extension (eg. rtg-logo-small.jpg)
// version: version number (eg. 1.0.0). Each unique url including version will be cached. This will help prevent images not being updated to cache when they are changed
// folderName: if the files are not in the root of the container
// containerName: if the files are not in the default container (specified in buildSettings.json)
export const generateAssetUrl = (assetId: string, imageType?: ImageType,assetName?: string, assetType?:string): string =>
  (assetType !=='MIS')?`${config.CDN_URL}?id=${assetId}${imageType ? '&prid=' + imageType : ''}`:
  `${config.MIS_URL}${assetName}.pdf`

