import React, { FC, ReactElement } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import {Image} from '../shared/media'
import {generateAssetUrl } from '../helpers';
import { gridRow } from "styled-system";

export interface IHeaderProps {}

export const About : FC<IHeaderProps> = () : ReactElement => {

    return (
           <Container className='about-container' role="main">
               <a className="hide508" href="#about">Skip to Main Content</a>
               <Row className='about-image-row'>
                <Image
                    maxWidth='100%'
                    height='auto'
                    // maxHeight='75%'
                    width='100%'
                    padding="0"
                    src={generateAssetUrl('34633402','caas-standard')}
                    alt={``}
                    role='presentation'
                    aria-hidden='true'
                />
                <div id="about" className="about-label">
                    <h1 className="label-text">About HM Richards</h1>
                </div>
                </Row>
                <Row className='about-image-row-mob'>
                <Image
                    maxWidth='100%'
                    height='100%'
                    // maxHeight='75%'
                    width='100%'
                    padding="0"
                    src={generateAssetUrl('34829732','caas-standard')}
                    alt={``}
                    role='presentation'
                    aria-hidden='true'
                />
                </Row>

                <div className='about-text-container'>
                    <Row className='about-heading'>
                        <h1 className="label-text-header">Our Company</h1>
                    </Row>
                    <Row className='about-description'>
                        <div style={{marginBottom: '20px'}}>
                        Founded in 1997, H.M. Richards, Inc. is a privately held upholstery company located in Baldwyn, MS. Our attention to detail and high 
                        standards are achieved by controlling our manufacturing processes in a single 1.3 million square foot campus. Within that campus is a 60,000 
                        square foot state of the art frame cutting facility and a 30,000 square foot space dedicated to cutting polyfoam cushions. 
                        </div>
                        <div>
                        Our distribution includes an elite group of furniture retailers across the United States and Canada. They are market leaders in their respective areas.
                        </div>
                    </Row>
                </div>
           </Container>
    )
   
}