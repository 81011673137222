function createCORSRequest(url: string, method: string) {
  var xhr = new XMLHttpRequest();
  if ('withCredentials' in xhr) {
    xhr.open(method, url, true);
    xhr.responseType = 'blob';
  } else {
    return null;
  }
  return xhr;
}
export function makeCorsImageRequest(
  url: string,
  method: string = 'GET',
  callback: (body: string | ArrayBuffer | null, err?: string) => void
) {
  var xhr = createCORSRequest(url, method);
  if (xhr) {
    xhr.onload = () => {
      var reader = new FileReader();
      reader.onload = () => {
        return callback(reader.result);
      };
      if (xhr) {
        reader.readAsDataURL(xhr.response);
      }
    };
    xhr.send();
  } else {
    console.log('CORS not supported');
    return;
  }
}
