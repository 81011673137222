import React, { FC, ReactElement } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import {Image} from '../shared/media'
import {generateAssetUrl } from '../helpers';
import { gridRow } from "styled-system";

export interface IHeaderProps {}

export const Quality : FC<IHeaderProps> = () : ReactElement => {

    return (
        <Container style={{maxWidth: '100%', margin: '0',  position: 'relative'}}>
               <a className="hide508" href="#quality">Skip to Main Content</a>
            <Row className='quality-image'>
            <Image
                maxWidth='100%'
                height='auto'
                maxHeight='75%'
                width='100%'
                padding="0"
                src={generateAssetUrl('34633400','caas-standard')}
                alt={``}
                role='presentation'
                aria-hidden='true'
            />
            <div id="quality" className="about-label" role="main">
                <h1 className="label-text">Quality</h1>
            </div>
            </Row>
            <Row className='quality-image-mob'>
            <Image
                maxWidth='100%'
                height='auto'
                maxHeight='75%'
                width='100%'
                padding="0"
                src={generateAssetUrl('33584892','caas-standard')}
                alt={``}
                role='presentation'
                aria-hidden='true'
            />
            </Row>

            <Container className="web-quality-view">
                <Row>
                    <Col xs={4} style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33583819','caas-standard')}
                        alt={`frames image one`}
                    />
                    </Col>
                    <Col xs={4} className='quality-text-container'>
                        <div className="quality-details-container frames-container">
                            <div className="quality-header"><h6 className="quality-sec-header">FRAMES*</h6></div>
                            <ul className="quality-points">
                                <li>Kiln-dried hardwood and 3/4" hardwood laminate frames.</li>
                                <li>Stress joints are corner blocked, glued and stapled for strength.</li>
                                <li>Some joints are reinforced with mortise and tenon.</li>
                                <li>Additional hardwood pieces are added through out the frame for extra support.</li>
                            </ul>
                        </div>

                    </Col>
                    <Col xs={4} style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33583789','caas-standard')}
                        alt={`Frames Image second`}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className='quality-text-container'>
                    <div className="quality-details-container springs-container">
                            <div className="quality-header"><h6 className="quality-sec-header">SPRINGS*</h6></div>
                            <ul className="quality-points">
                                <li>8-gauge tempered and pre-arched metal wire sinuous springs.</li>
                                <li>Attached to the front and back rails using 16-guage Teflon coated clips.</li>
                                <li>Tightly spaced (5" on center) and cross-tied with tie wires in 2 locations.</li>
                                <li>These metal cross ties provide support and allow the system to move as one unit.</li>
                                <li>The sinuous springs are doubled up on the arms.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={4} style={{ padding: '0' }}>
                    <Image
                        maxWidth='100%'
                        height='100%'
                        maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33583809','caas-standard')}
                        alt={`Springs Image`}
                    />
                    </Col>
                    <Col xs={4} className='quality-text-container'>
                    <div className="quality-details-container tailoring-container">
                            <div className="quality-header" role="main"><h1 className="quality-sec-header">TAILORING*</h1></div>
                            <ul className="quality-points">
                                <li>Seams stitching sewn with 7 stitches per inch provides crispness and seam strength.</li>
                                <li>Skirts, where used, are lined for a straight hang. </li>
                                <li>Fabric patterns have a "four-way match" both horizontally and vertically.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33583781','caas-standard')}
                        alt={`Cushions Image One`}
                    />
                    </Col>
                    <Col xs={4} className='quality-text-container'>
                    <div className="quality-details-container frames-container">
                            <div className="quality-header" role="main"><h1 className="quality-sec-header">CUSHIONS AND FOAM PADDING*</h1></div>
                            <ul className="quality-points">
                                <li>5-ply reversible seat cushion consisting of 1.8 lb/cu. ft. foam core, with an additional foam layer on top and bottom, wrapped with a 3 oz. densified polyster fiber layer for softness and comfort.</li>
                                <li>Luxurious fiber fills the inner bags that are within the back, bolster and decorative pillows.</li>
                                <li>360 degree fully padded frames consisting of fiberboard, foam and then fabric.</li>
                                <li>Contour cut polyurethane top arm pads provide both comfort and uniform arm shape. </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={4} style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33583797','caas-standard')}
                        alt={`Cushions Image Two`}
                    />
                    </Col>
                </Row>
                <Row >
                    <div className="quality-disclaimer">
                    *Applies to most products
                    </div>
                </Row>
            </Container>

            <Container className="mobile-quality-view">
                <h1 className='quality-header-mob'>FRAMES*</h1>
                <Row>
                    <Col xs={6} style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        // maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33665006')}
                        alt={`Frames Image`}
                    />
                    </Col>
                    <Col xs={6} className='quality-text-container'>
                        <div className="quality-details-container">
                            {/* <div className="quality-header" role="main"><h1 className="quality-header-mob">FRAMES</h1></div> */}
                            <ul className="quality-points">
                                <li>Kiln-dried hardwood and 3/4" hardwood laminate frames.</li>
                                <li>Stress joints are corner blocked, glued and stapled for strength.</li>
                                <li>Some joints are reinforced with mortise and tenon.</li>
                                <li>Additional hardwood pieces are added through out the frame for extra support.</li>
                            </ul>
                        </div>

                    </Col>
                </Row>
                <h1 className='quality-header-mob'>SPRINGS*</h1>
                <Row>
                    <Col xs={6} className='quality-text-container'>
                        <div className="quality-details-container">
                                {/* <div className="quality-header" role="main"><h1 className="quality-header-mob">SPRINGS</h1></div> */}
                                <ul className="quality-points">
                                    <li>8-gauge tempered and pre-arched metal wire sinuous springs.</li>
                                    <li>Attached to the front and back rails using 16-guage Teflon coated clips.</li>
                                    <li>Tightly spaced (5" on center) and cross-tied with tie wires in 2 locations.</li>
                                    <li>These metal cross ties provide support and allow the system to move as one unit.</li>
                                    <li>The sinuous springs are doubled up on the arms.</li>
                                </ul>
                            </div>
                    </Col>
                    <Col xs={6} style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        // maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33665005')}
                        alt={`Springs Image`}
                    />
                    </Col>
                </Row>
                <h1 className='quality-header-mob'>TAILORING*</h1>
                <Row>
                <Col xs={6} style={{ padding: '0' }}>
                    <Image
                        maxWidth='100%'
                        height='100%'
                        // maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33665008')}
                        alt={`Tailoring Image`}
                    />
                    </Col>
                <Col xs={6} className='quality-text-container'>
                    <div className="quality-details-container">
                            {/* <div className="quality-header" role="main"><h1  className="quality-header-mob">TAILORING</h1></div> */}
                            <ul className="quality-points">
                                <li>Seams stitching sewn with 7 stitches per inch provides crispness and seam strength.</li>
                                <li>Skirts, where used, are lined for a straight hang. </li>
                                <li>Fabric patterns have a "four-way match" both horizontally and vertically.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={3} style={{ padding: '0' }}></Col>
                </Row>

                <h1 className='quality-header-mob'>CUSHIONS AND FOAM PADDING*</h1>
                <Row>
                    <Col xs={6} className='quality-text-container'>
                        <div className="quality-details-container">
                                {/* <div className="quality-header" role="main"><h1 className="quality-header-mob">CUSHIONS AND FOAM PADDING</h1></div> */}
                                <ul className="quality-points">
                                    <li>5-ply reversible seat cushion consisting of 1.8 lb/cu. ft. foam core, with an additional foam layer on top and bottom, wrapped with a 3 oz. densified polyster fiber layer for softness and comfort.</li>
                                    <li>Luxurious fiber fills the inner bags that are within the back, bolster and decorative pillows.</li>
                                    <li>360 degree fully padded frames consisting of fiberboard, foam and then fabric.</li>
                                    <li>Contour cut polyurethane top arm pads provide both comfort and uniform arm shape. </li>
                                </ul>
                            </div>
                    </Col>
                    <Col xs={6}  style={{ padding: '0' }}>
                        <Image
                        maxWidth='100%'
                        height='100%'
                        // maxHeight='100%'
                        width='100%'
                        padding="0"
                        src={generateAssetUrl('33728729')}
                        alt={`Quality Image`}
                    />
                    </Col>
                </Row>
                <Row>
                    <div className="quality-disclaimer">
                    *Applies to most products
                    </div>
                </Row>
              
            </Container>

        </Container>
    )
   
}