import React, { FC, ReactElement } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Image } from "../../shared/media";
import { generateAssetUrl } from "../../helpers";
import { gridRow } from "styled-system";
import { StyleSheetManager } from "styled-components";

export interface IHeaderProps {}

export const Home: FC<IHeaderProps> = (): ReactElement => {
  return (
    <Container className="home-container">
      <a className="hide508" href="#home">
        Skip to Main Content
      </a>
      <Row className="home-image-container">
        <Image
          maxWidth="100%"
          width="100%"
          maxHeight="100%"
          padding="0"
          src={generateAssetUrl("34229648", "caas-standard")}
          alt={``}
          role="presentation"
          aria-hidden="true"
        />
      </Row>
      <Row className="home-image-container-mob">
        <Image
          maxWidth="100%"
          maxHeight="100%"
          width="100%"
          padding="0"
          src={generateAssetUrl("33583773", "caas-standard")}
          alt={``}
          role="presentation"
          aria-hidden="true"
        />
      </Row>

      <Row className="home-info-row">
        <Col sm={4} xs={12} md={4} lg={4} xl={4} className="information-col">
          <Container className="info-text">
            <Row id="home" className="upper-text-container">
              <h1 className="furniture-header-web">FURNITURE FOR EVERY</h1>
            </Row>
            <Row className="lifestyle-container">
              <div className="lifestyle-text">LIFESTYLE</div>
            </Row>
            <Row className="lower-text-container"></Row>
          </Container>
        </Col>
        <Row className="text-container">
          <Col xs={6} className="info-text-mobile" role="main">
            <Row className="info-text-one">
              <h1 className="furniture-header-mob">
                FURNITURE FOR EVERY
                <div className="shadow-for-text"></div>
              </h1>
            </Row>
          </Col>
          <Col xs={6} className="lifestyle-text-mobile">
            <h1 className="lifestyle-header-mob">LIFESTYLE</h1>
          </Col>
        </Row>
        <Col sm={8} xs={12} md={8} lg={8} xl={8} className="second-home-image">
          <Image
            maxWidth="100%"
            // height='100%'
            maxHeight="100%"
            width="100%"
            padding="0"
            src={generateAssetUrl("34633401", "caas-standard")}
            alt={``}
            role="presentation"
            aria-hidden="true"
          />
        </Col>

        <Col sm={7} xs={12} md={8} lg={7} className="second-home-image-mob">
          <Image
            maxWidth="100%"
            // height='100%'
            maxHeight="100%"
            width="100%"
            padding="0"
            src={generateAssetUrl("34829729", "caas-standard")}
            alt={``}
            role="presentation"
            aria-hidden="true"
          />
        </Col>
      </Row>
    </Container>
  );
};
